<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <vue-table
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getParams"
          @onEdit="onEdit"
        />
      </md-card-content>
    </md-card>

    <SystemModal
      v-if="editingParam"
      :parameter="editingParam"
      @close="editingParam=null"
      @onSave="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import SystemModal from './SystemModal.vue';

export default {
  components: { VueTable, SystemModal },
  data: () => ({
    loading: false,
    editingParam: false,
    vTable: {
      headers: [
        {
          title: 'name',
          sortable: true,
        },
        {
          title: 'value',
          boolean: true,
          booleanValues: {
            true: 'ACTIVATED',
            false: 'DEACTIVATED',
          },
        },
      ],
      values: {},
      actions: [
        {
          buttonClass: 'md-info',
          tooltip: 'Edit Parameter',
          callback: 'onEdit',
          icon: 'edit',
        },
      ],
    },
  }),
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getParams(evt) {
      this.loading = true;
      this.request('system', 'get', { ...evt }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    onEdit(item) {
      this.editingParam = item;
    },
  },
};
</script>

<style>

</style>
