<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Edit Parameter - {{ result.name }}
    </template>
    <template slot="body">
      <FormText
        v-if="result.type === 'text'"
        v-model="result.value"
        label="Value"
      />
      <FormNumber
        v-if="result.type === 'number'"
        v-model="result.value"
        label="Value"
      />
      <FormSelect
        v-if="result.type === 'options'"
        v-model="result.value"
        label="Value"
        :options="result.options"
      />

      <md-switch
        v-if="result.type === 'boolean'"
        v-model="result.value"
      >
        Deactivated / Activated
      </md-switch>
    </template>
    <template slot="footer">
      <md-button
        class="md-success md-sm"
        @click="saveParam"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormText,
  FormNumber,
  FormSelect,
} from '@/components/Inputs';

export default {
  components: {
    FormText,
    FormNumber,
    FormSelect,
  },
  props: {
    parameter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      result: { ...this.parameter },
      previous_value: null,
    };
  },
  mounted() {
    this.previous_value = this.parameter.value;
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveParam() {
      if (this.previous_value === this.result.value) {
        this.fireWarning('Value has not been change');
        this.close();
        return;
      }
      this.request(`system/${this.result.system_parameter_id}`, 'put', { ...this.result }, () => {
        this.close();
        this.fireSuccess('System Parameter successfully updated!. Please Sign In again');
        this.cleanSession();
        this.$router.push({ name: 'Login' });
      });
    },
  },
};
</script>
<style></style>
